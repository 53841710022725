.expertContainer {
  font-size: 14px;
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &__expert {
    height: 120px;
    width: 290px;
    margin: 10px;
    display: flex;
    &__logo {
      width: 200px;
      height: 93px;
      border-radius: 80px;
      margin-right: 5px;
      overflow: hidden;
      & img {
        width: 100%;
        height: auto;
        border-radius: 46px;
      }
    }
    &__info {
      flex-grow: 1;
      padding: 10px 6px 0 6px;
      &__name {
        font-weight: bold;
        margin-bottom: 5px;
      }
      &__desc {
        font-size: 14px;
      }
    }
  }
}

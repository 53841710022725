// .resource {
//   border-top: 1px solid #c3bdb7;
//   border-bottom: 1px solid #c3bdb7;
// }

.resource ul {
  //list-style: none;
  padding-left: 10px;
  color: #7b7b7b;
  margin: 5px 0 40px 15px;
}

.resource ul li {
  line-height: 54px;
  font-size: 16px;
}

.resource ul li a {
  display: block;
  //padding: 10px 0;
  text-decoration: none;
  color: #4c4b4b;
  line-height: 24px;
  &:hover {
    text-decoration: underline;
  }
}

.button {
  height: 24px;
  margin: 3px;
  padding: 3px 8px;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.button-link {
  height: 24px;
  margin: 3px;
  padding: 3px 8px;
  border-radius: 3px;
  font-size: 14px;
  color: #4c67ff;
  cursor: pointer;
  text-decoration: underline;
}

.button-mb {
  height: 28px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 9px 18px;
}

.button:hover {
  box-shadow: 1px 1px 1px 1px #c7c3c3;
}

.textarea {
  border-collapse: collapse;
  border: 1px solid #aaa;
  width: 100%;
  font-size: 14px;
}

.textarea:focus {
  outline: none;
}

.input {
  outline-style: none;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 2px 2px;
  width: 95%;
  font-size: 14px;
  font-family: "Microsoft soft";
}

.input:focus {
  outline: none;
}

.tag {
  margin: 5px;
  padding: 5px 8px;
  border-radius: 5px;
}

.tag-error {
  margin: 5px;
  padding: 2px 8px;
  border-radius: 5px;
  color: red;
  background-color: #fdceca;
}

.tag-success {
  margin: 5px;
  padding: 2px 8px;
  border-radius: 5px;
  color: green;
  background-color: #def5df;
}

.tag-grey {
  margin: 5px;
  padding: 2px 8px;
  border-radius: 5px;
  color: grey;
  background-color: #e2e2e2;
}

.tag-yellow {
  margin: 5px;
  padding: 2px 8px;
  border-radius: 5px;
  color: #fff;
  background-color: #ff9800;
}

.hide {
  display: none;
}

.bg-primary {
  background: #ff9800;
}

.bg-gray {
  background: #a7a6a6;
}

.bg-blue {
  background: #3a68a0;
}

.bg-red {
  background: red;
}

.errorMsg {
  color: red;
}

.list__title {
  border-bottom: 2px solid rgb(185, 185, 185);
  padding-bottom: 10px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
}

.listItem {
  display: flex;
  justify-content: left;
  line-height: 24px;
  border-bottom: 1px solid #e9e9e9;
  padding: 20px 0;
  margin-bottom: 20px;
  &__img {
    margin-right: 20px;
    max-width: 300px;
    min-width: 300px;
    max-height: 168px;
    min-height: 168px;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: hidden;
    text-align: center;
    overflow: hidden;
  }
  &__img img {
    max-width: 300px;
    min-width: 300px;
    max-height: 168px;
    min-height: 168px;
  }

  // &__img img:hover {
  //   filter: alpha(opacity=80);
  //   opacity: 0.8;
  // }
  &__info {
    width: 100%;
    &--light {
      font-size: 14px;
      color: #707070;
    }
    &__title {
      color: #3c3c3c;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
      // &:hover {
      //   text-decoration: underline;
      //   cursor: pointer;
      // }
      & a {
        color: #3c3c3c;
      }
    }

    &__subtitle {
      color: #3c3c3c;
      font-size: 14px;
      line-height: 24px;
    }

    &__desc {
      margin-top: 5px;
      font-size: 14px;
      color: #797979;
      line-height: 26px;
      background: #f3f3f3;
      padding: 10px;
      min-height: 30px;
      margin-top: 15px;
    }
    &__bottom {
      margin-top: 8px;
    }
  }
}

.tabpanel {
  display: flex;
  border-bottom: 2px solid #d4d4d4;
  line-height: 22px;
  padding-left: 10px;
  justify-content: left;

  & .tab {
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      background-color: #d4d4d4;
    }
  }

  & .selected {
    background-color: #d4d4d4;
  }

  & .divider-vertical {
    width: 1px;
    margin: 10px 8px;
    background: #000;
    border: 5px;
  }
}

.phase__tabpanel {
  display: flex;
  border-bottom: 4px solid #e8e8e8;
  line-height: 38px;
  justify-content: left;
  font-size: 16px;
  &__tab {
    padding: 5px 10px;
    font-size: 16px;
    background-color: #e8e8e8;
    border-radius: 5px;
    margin: 2px 4px 4px 0;
    cursor: pointer;
    &--selected {
      margin: 0 6px 0 0;
      border-radius: 5px 5px 0 0;
      //box-shadow: 2px -1px 3px -2px;
    }
  }

  &__content {
    padding: 5px 10px;
    background-color: #e8e8e8;
    min-height: 50px;
    border-radius: 0 0 5px 5px;
    //box-shadow: 2px 2px 4px -2px;
  }
}

$gl-gutter-vertical: 8px;
$gl-gutter: 0.5rem;

@import "gridlex/src/gridlex.scss";

.table {
  border-collapse: collapse;
  margin: 0 auto;
  text-align: center;
  width: 100%;
}
.table td,
.table th {
  border: 1px solid #9e9e9e61;
  color: #666;
  height: 36px;
}
.table thead th {
  background-color: #e8e8e8;
}
.table tr:nth-child(odd) {
  background: #fff;
}
// .table tr:nth-child(even) {
//   background: #f5fafa;
// }

.table .input:focus {
  outline: none;
}

.table td .textarea {
  width: 98%;
  font-size: 14px;
}

.table .textarea:focus {
  outline: none;
}

#subjectRequest a {
  color: #ff9800;
}

#subjectRequest a:hover {
  text-decoration: underline;
  color: #ff9800;
}

.cardbox {
  background: #fafafa;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 1px #d2d2d2;
  padding: 10px;
  margin: 20px 0;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.resource-braft-btn {
  color: #ffffff !important;
  font-size: 14px !important;
  background: #e91e63 !important;
  border: 1px solid #d2d2d2 !important;
}

.resource-braft-btn:hover {
  box-shadow: 1px 1px 1px 1px #d2d2d2 !important;
}

.upload-file-list {
  margin-top: 10px;
}

.upload-file-list .toolsbar {
  line-height: 30px;
  margin-left: 10px;
}

.upload-file-list .toolsbar .operation {
  margin: 2px;
}

.upload-file-list .toolsbar .operation svg:hover {
  cursor: pointer;
  font-size: 20px;
}

.swiper-pagination {
  text-align: right !important;
  padding-right: 40px;
  bottom: 10px;
}

.swiper-pagination-bullet {
  width: 16px !important;
  margin: 5px !important;
  height: 16px !important;
  background: #fff;
}

.swiper-button-next {
  margin-right: 15px;
}
.swiper-button-prev {
  //width: 100px;
  margin-left: 15px;
}

.swiper-button-next img {
  width: 30px;
}

.swiper-button-prev img {
  width: 30px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  content: "";
  font-size: 0;
}

#questionnaire-logo .ant-upload-list-picture-card-container {
  width: 100% !important;
}

#questionnaire-logo .ant-upload-list-picture-card .ant-upload-list-item {
  width: 100% !important;
  height:168px !important;
}

#questionnaire-logo .ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0 !important;
  border: 0 !important;
}

#questionnaire-logo .ant-upload.ant-upload-select-picture-card{
  width: 100% !important;
  height: 168px !important;
}

#questionnaire-logo .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img{
  width: 100% !important;
  height: 168px !important;
}


#requestTrainer .ant-upload-list-picture-card-container {
  width: 100% !important;
}

#requestTrainer .ant-upload-list-picture-card .ant-upload-list-item {
  width: 100% !important;
  height:168px !important;
}

#requestTrainer .ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0 !important;
  border: 0 !important;
}

#requestTrainer .ant-upload.ant-upload-select-picture-card{
  width: 100% !important;
  height: 168px !important;
}

#requestTrainer .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img{
  width: 100% !important;
  height: auto !important;
}

#requestTrainer .ant-upload-list-picture-card-container{
  height: 168px !important;
}


#requestTrainClasses {
   font-size: 14px;
}
#requestTrainClasses .ant-upload-list-picture-card-container {
  width: 300px !important;
  height: 168px !important;
}

#requestTrainClasses .ant-upload-list-picture-card .ant-upload-list-item {
  width: 300px !important;
  height:168px !important;
}

#requestTrainClasses .ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0 !important;
  border: 0 !important;
}

#requestTrainClasses .ant-upload.ant-upload-select-picture-card{
  width: 300px !important;
  height: 168px !important;
}

#requestTrainClasses .ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img{
  width: 300px !important;
  height: 168px !important;
}
.wrapcontainer {
  padding: 30px 20px 20px;
  //border-top: 1px solid #c3bdb7;
  border-bottom: 1px solid #c3bdb7;

  &__head {
    display: flex;
    //justify-content: space-between;
    line-height: 32px;
    padding: 0 10px 0 10px;

    margin-bottom: 15px;
    color: #000;
    &__title {
      font-size: 28px;
      //font-weight: bold;
      padding-right: 20px;
    }
    &__more {
      padding-top: 6px;
    }

    &__more a {
      font-size: 14px;
      text-decoration: "underline";
      color: #2f72a8 !important;
      padding-left: 12px;
      border-left: 1px solid #c5c5c5;
    }
  }
  &__row {
    display: flex;
    justify-content: left;
    &__col {
      width: 320px;
      padding: 10px;
    }
  }
}

.contentItem {
  width: 100%;
  &__img {
    background-color: #d6d6d6;
    max-height: 158px;
    min-height: 158px;
    max-width: 280px;
    min-width: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  &__img img {
    max-height: 158px;
    min-height: 158px;
    max-width: 280px;
    min-width: 280px;
  }

  &__img img:hover {
    filter: alpha(opacity=80);
    opacity: 0.6;
  }

  &__content {
    width: 100%;
    height: 160px;
    margin: 0 0 10px 0;
    padding: 20px 10px 0 0;
    overflow: hidden;
    font-size: 14px;
    line-height: 26px;
  }
  &__content__title {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
    //letter-spacing:2px;
    & a:hover {
      text-decoration: underline;
      color: #be0f34;
    }
    & a {
      color: #000;
      text-decoration: none;
    }
  }

  &__content__subtitle {
    color: #746d69;
    font-size: 14px;
  }
  &__content__desc {
    font-size: 14px;
    height: 46px;
    overflow: hidden;
  }
}
